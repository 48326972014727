import axios from 'axios';

const baseUrl = 'https://raw.githubusercontent.com/DavydeVries/davy.dev-feed/main/';

export default {
    async fetchJsonData() {
        try {
            const response = await axios.get(`${baseUrl}images.json`);
            return response.data;
        } catch (error) {
            throw new Error('Error fetching JSON data');
        }
    },
};
