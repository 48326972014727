<template>
    <main class="relative grid min-h-full place-items-center bg-gray-100 px-6 py-24 sm:py-32 lg:px-8 overflow-hidden">
        <div class="max-w-xl mx-auto">
            <div class="text-center">
                <p class="text-base font-semibold text-blue-600">PixelArt Challenge!</p>
                <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Davy de Vries</h1>
                <p class="mt-6 text-base leading-7 text-gray-600 text-balance">

                    🚀 Goal: I aim to create 100 captivating pixel art pieces this year.</p>
                <div class="mt-10 flex items-center justify-center gap-x-6">
                    <FeedModal :images="images"></FeedModal>
<!--                    <a @click="handleOpen" class="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">Show feed</a>-->
                    <!--          <a href="#" class="text-sm font-semibold text-gray-900">Contact support <span aria-hidden="true">&rarr;</span></a>-->
                </div>
            </div>
        </div>
        <img class="pixelated absolute hidden lg:block"
            v-for="(image, index) in images"
            :src="'https://raw.githubusercontent.com/DavydeVries/davy.dev-feed/main/src/'+image.src"
            :alt="image.name"
            :key="index"
            :style="{
        top: image.top || 'auto',
        bottom: image.bottom || 'auto',
        left: image.left || 'auto',
        right: image.right || 'auto',
      }">
    </main>
</template>

<script>
import GitHub from '@/services/GitHub';
import FeedModal from '@/components/FeedModal';

export default {
    name: 'App',
    components: {
        FeedModal
    },
    data() {
        return {
            feedModalOpen: false,
            images: null,
        };
    },
    async mounted() {
        this.images = await GitHub.fetchJsonData();
    },
}
</script>

<style>
#app {
    @apply h-screen w-full;
}
</style>
