<template>
    <div class="flex py-4 lg:py-8">
        <div class="mr-4 flex-shrink-0 self-center">
            <div class="size-16 rounded-xl bg-gray-100 flex items-center overflow-hidden">
                <img :src="'https://raw.githubusercontent.com/DavydeVries/davy.dev-feed/main/src/'+image.src" class="pixelated mx-auto">
            </div>
        </div>
        <div>
            <div class="flex items-center gap-x-4 text-xs">
                <h4 class="text-lg font-bold">{{ image.name }}</h4>
                <time :datetime="image.date" class="text-gray-500">{{image.date}}</time>
            </div>
            <p class="mt-1 text-balance">{{ image.desc }}</p>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        image: {
            type: Object
        }
    }
}
</script>